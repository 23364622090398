.logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border: solid #14a52c 2px;
  border-radius: 25px;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.username {
  font-size: 0.875rem;
}

.links {
  list-style: none;
  padding-left: 0;
  border-radius: 1.5rem;
}

.link {
  font-size: 0.875rem;
  border-radius: 1.5rem;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.link:hover {
  background-color: #ddffa1;
  color: #2d3632;
  margin-left: 1rem;
  border-radius: 25px;
}
.active {
  background-color: #15803d;
  color: #fff;
  margin-left: 1rem;
  border-radius: 25px;
}
.mobile-menu-icon {
  position: absolute;
  top: 2px;
  left: 5px;
  padding: 2px;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  font-size: 1.2rem;
  cursor: pointer;
}
.drop-btn {
  position: absolute;
  top: 6rem;
  right: 9rem;
  width: 180px;
  padding: 5px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid gray;
}
.notification-btn {
  position: absolute;
  top: 4rem;
  /* right: 9rem; */
  width: 300px;
  padding: 8px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid gray;
}
/* .drop-btn::before {
  content: "";
  position: absolute;
  top: -0.7rem;
  right: -1.1rem;
  width: 20px ;
  height: 25px;
  transform: rotate(45deg);
  background-color: white;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
} */
