@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Century Gothic';
    src: url('./fonts/Century\ Gothic.ttf') format('truetype');
  }
  
  body {
    font-family: 'Century Gothic', sans-serif;
  }

  :root {
    --background-color: #F4F7F7;
    --text-color: #000000;
  }
  
  .dark-mode {
    --background-color: #1e1e1e;
    --text-color: #ffffff;
  }

  
  .dashboard-contain {
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100vh;
  }
  

  .mode-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: transparent;
    border: none;
    color: var(--text-color);
    font-size: 1.5rem;
    cursor: pointer;
  }
  
/* 
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 8px; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
} */
