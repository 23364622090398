/* SideNavbar.css */
.sidenav {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    border-right: 2px solid #ddd; 
    background-color: transparent; 
    padding-right: 1rem;
  }

  .sidenav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 5rem;
  }
  
  .sidenav-item {
    width: 100%;
  }
  
  .sidenav-link {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    text-decoration: none;
    color: #666; 
    font-size: 16px;
    transition: color 0.3s ease, border-right 0.3s ease;
  }
  
  .sidenav-link .icon {
    font-size: 20px;
    margin-right: 15px;
  }
  
  .sidenav-link:hover {
    color: #1abc9c; 
  }
  
  .sidenav-link.active {
    color: #f0f6f5; 
    font-weight: bold;
    /* border-right: 3px solid #1abc9c; */
  }
  
  .sidenav-link.active .icon {
    color: #f0f6f5; 
  }
  
  .sidenav-link .icon svg {
    fill: #A1A1AA; 
  }
  
  .sidenav-link.active .icon svg {
    fill: #f0f6f5;
  }
  .sidenav-link:hover .icon svg {
    fill: #1abc9c;
  }
  @media (max-width: 768px) {
    .sidenav {
      width: 200px;
      /* background-color: #f0eded; */
      z-index: 5;
      top: 10;
      padding-top: 10em;
      height: 50vh;
    }
  
    .sidenav-link {
      font-size: 14px;
    }
  
    .sidenav-link .icon {
      font-size: 18px;
    }
  }
  