input.inputBox {
    font-size: 22px;
    padding: 5px 8px 4px 8px;
    border-radius: 3px;
    border: 1px solid #666;
}
.calendarWrap{
    display: inline-block;
    position: relative;
}
.calendarElement{
    position: absolute;
    left: 10%;
    transform: translateX(-90%);
    top: 40px;
    border: 1px solid #ccc;
    z-index: 999;
}